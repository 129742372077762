
import axios from "axios";
import { Message } from "element-ui";
import store  from "@/store";
import router from "../router/index"
import { i18n } from '../locale/index.js' //国际化



const http = axios.create({
    // baseURL: 'https://a.darty-ap.com'
    baseURL: 'https://www.spar-mall.cc'
});
// 请求超时时间
// axios.defaults.timeout = TIME_OUT;

// axios.defaults.withCredentials = true;

// 封装请求拦截
http.interceptors.request.use(
  config => {
  

    let token = localStorage.getItem("token");
    config.headers["Lang"] = store.state.country
    config.headers["mtype"] = store.state.moneyType
   if(token){

    config.headers["Token"] = token.replace(/"/g,'')
    
    
   }
    
    return config;
  },
  error => {
    
    return Promise.reject(error);
  }
 
);

// 封装响应拦截，判断token是否过期
http.interceptors.response.use(
  response => {

  //   setTimeout(() => {
  //     translate.language.setLocal('english'); //设置本地语种（当前网页的语种）。如果不设置，默认自动识别当前网页显示文字的语种。 可填写如 'english'、'chinese_simplified' 等，具体参见文档下方关于此的说明。
  // translate.service.use('client.edge'); //设置机器翻译服务通道，直接客户端本身，不依赖服务端 。相关说明参考 http://translate.zvo.cn/43086.html
  // translate.execute();//进行翻译 
  //   },0)
      // alert(JSON.stringify(response) )
      
      if(response.data.code==0){
      
        Message.error(i18n.t( response.data.msg))
      }
      
      return Promise.resolve(response);
    
  },
  error => {
   if(error.message = "Request failed with status code 401"){
      Message.error(i18n.t( "text94"))
      router.push("/")
      return
   }
    return Promise.reject(error);
  }
);

export default http


